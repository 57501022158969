// @ts-nocheck
import React from 'react';
declare global {
  interface Window {
    dacast: any;
  }
}

const defaultConfig: PlayerOptions = {
  autoplay: false,
  provider: 'universe',
};

export const usePlayer = (
  playerRef: React.MutableRefObject<HTMLDivElement | null>,
  contentId: string,
  config?: PlayerOptions
) => {
  const [player, setPlayer] = React.useState<any>(null);

  const initPlayer = () => {
    let player = dacast(
      contentId,
      playerRef.current,
      config || defaultConfig
    ) as any;
    setPlayer(player);
  };

  React.useEffect(() => {
    if (playerRef && playerRef.current) {
      let existingPlayerTag = Array.from(
        document.getElementsByTagName('script')
      ).find(s => s.src.indexOf('player.dacast.com/js/player.js') !== -1);
      if (!existingPlayerTag) {
        let head = document.head || document.getElementsByTagName('head')[0];
        const playerScript = document.createElement('script');
        playerScript.src =
          'https://player.dacast.com/js/player.js?contentId=' + contentId;
        head.insertBefore(playerScript, head.firstChild);
        playerScript.addEventListener('load', initPlayer);
      } else {
        initPlayer();
      }
    }
    return () => {
      if (typeof dacast !== 'undefined') {
        if (player) {
          console.log('called');
          player.dispose();
        }
      }
    };
  }, []);
  return player;
};

export type OVPProvider = 'vzaar' | 'dacast' | 'universe' | 'singularity';

export type Player = 'theo' | 'vjs5' | 'jw';

export type ObjectFitCSS = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
export interface Ads {
  ads: {
    disableControls?: boolean;
    preRoll?: AdNode[] | null;
    midRoll?: MidRollNode[] | null;
    postRoll?: AdNode[] | null;
  };
}

export interface OldAds {
  adserver: string;
  options: {
    adTagUrl: string;
  };
}

export interface PlayerOptions {
  provider?: OVPProvider;
  width?: number | null;
  height?: number | null;
  player?: Player | null;
  jwUrl?: string | null;
  hlsQualities?: string | null;
  autoplay?: boolean | string | null;
  kioskMode?: boolean | string | null;
  loop?: boolean | string | null;
  playsinline?: boolean | string | null;
  startMute?: boolean | string | null;
  brandText?: string | null;
  brandLink?: string | null;
  disableControls?: boolean | null;
  hideFullscreen?: boolean | string | null;
  adPreRollUrl?: string | null;
  adMidRollUrl?: string | null;
  adMidRollTime?: number | null;
  adPostRollUrl?: string | null;
  showControlsForJSAds?: boolean | string | null;
  border?: boolean | null;
  colourAccent?: string | null;
  colourBase?: string | null;
  colourSet?: string | null;
  endText?: string | null;
  endLink?: string | null;
  showPlayButton?: boolean | string | null;
  previewThumbs?: boolean | string | null;
  preload?: boolean | string | null;
  hideControls?: boolean | string | null;
  socialSharing?: string | null;
  videoURL?: string | null;
  setSubsBg?: boolean | string | null;
  brandOverlayFS?: boolean | string | null;
  colourBg?: string | null;
  playlistMode?: string | null;
  continuousPlay?: boolean | string | null;
  hideBuffering?: boolean | string | null;
  language?: string | null;
  skipButtonsTime?: number | null;
  objectFitCSS?: ObjectFitCSS | null;
  hideMailCatcher?: boolean | null;
  lightWeight?: boolean | null;
  inplayertoken?: string | null;
  referrerToken?: string | null;
  signedKey?: string | null;
  subtitles?: string | null;
  relayGAEventsToParent?: boolean | null;
  ads?: Ads | OldAds;
  theme?: ThemeInfo;
}

export type PlayerOptionsNames = keyof PlayerOptions;

export interface Ads {
  ads: {
    disableControls?: boolean;
    preRoll?: AdNode[] | null;
    midRoll?: MidRollNode[] | null;
    postRoll?: AdNode[] | null;
  };
}

export interface AdNode {
  url?: string | null;
}

export interface MidRollNode extends AdNode {
  startTime?: number | null;
}

export type HexColor = string;

export interface ThemeInfo {
  backgroundColor: HexColor;
  overlayColor: HexColor;
  showBackground: boolean;
  border: boolean;
  darkMode: boolean;
  colorSet?: string;
  playerBackgroundColor?: string;
}
